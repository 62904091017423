@layer base {
  .main {
  margin-bottom: var(--sk-space-8);
}

.mainBig {
  display: none;
}

@media (--sk-medium-viewport) {
  .hiddenOnLargeScreen.main {
    display: none;
  }

  .displayedOnLargeScreen.mainBig {
    display: flex;
    flex: 0 0 var(--sk-size-72);
    flex-direction: column;
    margin-right: var(--sk-space-16);
  }

  .displayedOnLargeScreen .month {
    text-transform: uppercase;
  }

  .displayedOnLargeScreen .day {
    font-family: var(--sk-typography-display-2-font-family);
    font-size: var(--sk-typography-display-2-font-size);
    font-weight: var(--sk-typography-display-2-font-weight);
    line-height: var(--sk-typography-display-2-line-height);
  }

  .displayedOnLargeScreen .month + .day {
    margin-top: var(--sk-space-8);
  }
}

}